.product-cards-container {
  opacity: 0;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.product-cards-container-enter {
  opacity: 0;
  transform: translateX(50%);
  transition: opacity 500ms, transform 500ms;
}

.product-cards-container-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.product-cards-container-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.product-cards-container-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}
